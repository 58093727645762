import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';

const ImageFrame = ({ image, text }) => (
  <ImageFrameWrapper>
    <StyledImg fluid={image?.fluid} loading="lazy" />
    <ImageFrameText>{text}</ImageFrameText>
  </ImageFrameWrapper>
);

const ImageFrameWrapper = styled.div`
  display: flex;
  justify-content: center;
  //margin: ${mobileVW(100)} 0;
  position: relative;
  ${desktopBreakpoint} {
    //margin: ${desktopVW(200)};
  }
`;

const ImageFrameText = styled.div`
  font-family: 'messina';
  position: absolute;
  text-transform: uppercase;
  color: ${colors.lightGrey};
  font-size: ${mobileVW(50)};
  text-align: center;
  width: ${mobileVW(350)};
  top: 50%;
  transform: translate(0, -50%);
  ${desktopBreakpoint} {
    width: ${desktopVW(1150)};
    font-size: ${desktopVW(125)};
  }
`;

const StyledImg = styled(Img)`
  //height: ${desktopVW(1000)};
  width: ${mobileVW(200)};
  ${desktopBreakpoint} {
    width: ${desktopVW(400)};
  }
`;

export default ImageFrame;
